<template>
    <div id="MemberSystemAccount">
        <section id="personal-info" class="login-form-style">
            <h2>登録情報</h2>
            <ElForm
                label-position="top"
                label-width="200px"
                ref="userDetailsFormRef"
                :model="userDetailsFormData"
                :rules="userDetailsFormRules"
            >
                <ElFormItem
                    class="f14 f14-mb"
                    label="メールアドレス"
                    prop="email"
                >
                    <ElInput
                        v-model="userDetailsFormData.email"
                        type="email"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="名前" prop="name">
                    <ElInput
                        v-model="userDetailsFormData.name"
                        type="name"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="ふりがな" prop="furigana">
                    <ElInput
                        v-model="userDetailsFormData.furigana"
                        type="furigana"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="生年月日" prop="birth">
                    <ElDatePicker
                        v-model="userDetailsFormData.birth"
                        type="date"
                        format="YYYY/MM/DD"
                        value-format="YYYY/MM/DD"
                    ></ElDatePicker>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="電話番号" prop="phone">
                    <ElInput
                        v-model="userDetailsFormData.phone"
                        type="phone"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="住所" prop="address">
                    <ElInput
                        v-model="userDetailsFormData.address"
                        type="address"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="職業" prop="job">
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.job"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="親育番号" prop="memberId">
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.memberId"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="真資格"
                    prop="qualification"
                >
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.qualification"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem class="f14 f14-mb" label="担当真使" prop="role">
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.role"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="紹介者"
                    prop="recommender"
                >
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.recommender"
                    ></ElInput>
                </ElFormItem>
                <ElFormItem
                    class="f14 f14-mb"
                    label="所属親交所"
                    prop="location"
                >
                    <ElInput
                        disabled
                        v-model="userDetailsFormData.location"
                    ></ElInput>
                </ElFormItem>
                <div class="button-wrap">
                    <button
                        class="f18 f16-mb"
                        @click.prevent="submitUserDetailsForm"
                    >
                        変更内容を保存
                    </button>
                </div>
            </ElForm>
        </section>
    </div>
</template>
<script>
export default {
    name: 'MemberSystemAccount',
}
</script>
<script setup>
import { onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { getUserDetailsFormRules } from '@/utils/formRules'
import { updateUserData } from '@/service/api'
import { onBeforeRouteLeave } from 'vue-router'
import { ElMessageBox } from 'element-plus'
const store = useStore()

onMounted(async () => {
    initUserData()
})
onBeforeRouteLeave((to, from, next) => {
    let userState = store.getters.getUserState
    if (!userState.token) {
        next()
    } else {
        let flag = false
        for (let key of Object.keys(userDetailsFormData)) {
            if (userDetailsFormData[key] !== userState[key]) {
                flag = true
                break
            }
        }
        if (flag) {
            ElMessageBox.confirm(
                '変更内容が保存されていません。このままページを移動してよろしいですか？<br>変更を保存する場合は［変更内容を保存］ボタンを押してください',
                '警告',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'キャンセル',
                    type: 'warning',
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    next()
                })
                .catch(() => {
                    return false
                })
        } else {
            next()
        }
    }
})

function initUserData() {
    let userState = store.getters.getUserState
    Object.keys(userDetailsFormData).forEach((key) => {
        userDetailsFormData[key] = userState[key]
    })
}
const userDetailsFormRef = ref(null)
const userDetailsFormData = reactive({
    userId: '',
    email: '',
    name: '',
    birth: '',
    phone: '',
    address: '',
    job: '',
    memberId: '',
    qualification: '',
    role: '',
    recommender: '',
    location: '',
    furigana: '',
})
const userDetailsFormRules = getUserDetailsFormRules(userDetailsFormData)
function submitUserDetailsForm() {
    userDetailsFormRef.value.validate(async (valid) => {
        if (valid) {
            await updateUserData(userDetailsFormData)
            initUserData()
        }
    })
}
</script>
<style lang="scss" scoped>
@import '@/style/element.scss';
#MemberSystemAccount {
    h2 {
        margin-bottom: 1em;
    }
}

@media screen and (min-width: 768px) {
    #MemberSystemAccount {
        display: flex;
        #personal-info {
            flex: 0 1 300px;
            margin-right: 60px;
        }
        #donate-record {
            flex: 1 1;
        }
    }
}

@media screen and (max-width: 767px) {
    section {
        margin-bottom: 80px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
